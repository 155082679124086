import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthGuard} from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  },
  {
    path: '',
    loadChildren: './pages/no-cart/no-cart.module#NoCartPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'disable',
    loadChildren: './pages/disable/disable.module#DisablePageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'tickets',
    loadChildren: './pages/tickets/tickets.module#TicketsPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'controlpanel',
    loadChildren: './pages/controlpanel/controlpanel/controlpanel.module#ControlpanelPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'controlpanel/login',
    loadChildren: './pages/controlpanel/login/login.module#LoginPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'controlpanel/draft',
    loadChildren: './pages/controlpanel/panels/draftpanel/draftpanel.module#DraftpanelPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'controlpanel/product',
    loadChildren: './pages/controlpanel/panels/productpanel/productpanel.module#ProductpanelPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    loadChildren: './pages/no-cart/no-cart.module#NoCartPageModule',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}