import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {MessageService} from '../../services/message.service';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-contactless-payment',
  templateUrl: './contactless-payment.component.html',
  styleUrls: ['./contactless-payment.component.scss'],
})
export class ContactlessPaymentComponent implements OnInit, OnDestroy {
  public readonly StyleType = StyleType;

  @Input()
  element: HTMLElement;
  product: Product;
  arrow: string;
  selectedAdditions: Product[] = [];
  contactless_payment_type: Number;
  timer = 240;
  interval;

  orderText = "";
  terminalImage = "p66.png";
  terminalChipImage = "p66.png";
  extraHigh = "";
  seitatech = false;
  internalChipChek = false;
  blinking = false;
  times = 0;
  constructor(
      private modalCtrl: ModalController,
      public messageService: MessageService,
      public styleService: StyleService) { }

  ngOnInit() {
    this.messageService.SetFirebaseMessage("false", "use_chip");
    this.blinking = false;

    this.interval = setInterval(() => {
      if(this.internalChipChek != this.messageService.useChip) {
        this.internalChipChek = this.messageService.useChip;
        this.blinking = true;
      }

      if(this.blinking == true) {
        if(this.times > 4) {
          this.blinking = false;
          //this.messageService.useChip = false;
          //this.internalChipChek = false;
        } else {
          this.times++;
        }
      }
    }, 1000);

    if(this.contactless_payment_type == 8) {
      this.seitatech = true;
      this.terminalImage = "2-5_fritlagt.png";
      this.terminalChipImage = "3-2_fritlagt.png"

      if(this.product.price > 350) {
        this.extraHigh = "high";
      }
    }

    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer')
      }
    }, 1000)

    this.orderText = `${this.product.name || '-'} (${(Math.floor(this.product.price)) || '-'} kr.)`;
    if (this.selectedAdditions && this.selectedAdditions.length > 0) {
      this.selectedAdditions.forEach(add => {
        this.orderText += `, ${add.name || '-'} (${(Math.floor(add.price)) || '-'} kr.)`;
      })
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval)
  }

  dismiss(status) {
    clearInterval(this.interval)
    this.modalCtrl.dismiss({
      'status': status,
    })
  }

  isClubProduct()
  {
      return this.product.integration_info?.includes('club');
  }
}
