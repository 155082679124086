import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-signup-phone',
    templateUrl: './club-signup-phone.component.html',
    styleUrls: ['./club-signup-phone.component.scss'],
})
export class ClubSignupPhoneComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    phoneNumber = "";

    input_length = 8;

    baseTime = 40;

    timer = this.baseTime;
    interval;

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService) { }

    ngOnInit() {
        this.setTimer()

    }

    setTimer() {
        clearInterval(this.interval)
        this.timer = this.baseTime;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval)
                this.dismiss('timeout')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, number = undefined) {
        this.modalCtrl.dismiss({
            'status': status,
            'number': number
        })
    }

    keypadClick(val) {
        this.setTimer()
        if (this.phoneNumber.length < 8) {
            this.phoneNumber = this.phoneNumber + val;
        }
    }
    keypadClear() {
        this.phoneNumber = "";
    }

    keypadDelete() {
        this.setTimer()
        if (this.phoneNumber.length > 0) {
            this.phoneNumber = this.phoneNumber.substr(0, this.phoneNumber.length - 1)
        }
    }
}
