import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-payment-selector',
  templateUrl: './payment-selector.component.html',
  styleUrls: ['./payment-selector.component.scss'],
})
export class PaymentSelectorComponent implements OnInit {
  public readonly StyleType = StyleType;

  @Input()
  product: Product;
  allowLoyalty: boolean;
  allowWashclub: boolean;
  allowMobilePay: boolean;
  allowContactLess: boolean;
  selectedAdditions: Product[];
  additionText = "";

  math = Math;

  timer = 30;
  interval;

  buttonPressed = false;

  constructor(
      private modalCtrl: ModalController,
      public styleService: StyleService) { }

  ngOnInit() {
    if (this.selectedAdditions) {
      this.selectedAdditions.forEach(ad => {
        this.additionText += `, ${ad.name} (${Math.floor(ad.price)} kr.) `
      });
    }

    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer');
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  dismiss(status) {
    if(this.buttonPressed) {
      console.log('Blocked!')
      return;
    }
    this.buttonPressed = true;
    setTimeout(() => this.buttonPressed = false, 1000);
    clearInterval(this.interval);
    this.modalCtrl.dismiss({
      'status': status
    })
  }
}
