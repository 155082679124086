import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../entities/product';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-option-selector',
  templateUrl: './option-selector.component.html',
  styleUrls: ['./option-selector.component.scss'],
})
export class OptionSelectorComponent implements OnInit {
  public readonly StyleType = StyleType;

  @Input()
  product: Product;
  options: Product[];
  additions: Product[];

  optionWithBool: {product: Product, val: boolean, addition: boolean}[] = [];

  math = Math;

  constructor(
      private modalCtrl: ModalController,
      public styleService: StyleService) { }

  ngOnInit() {
    if (this.options) {
      this.options.forEach(product => {
        this.optionWithBool.push({product: product, val: false, addition: false})
      })
    }

    if (this.additions) {
      this.additions.forEach(product => {
        this.optionWithBool.push({product: product, val: false, addition: true})
      })
    }
  }

  dismiss(status) {
    let selectedSku: string[] = [];
    let selectedAdditions: Product[] = [];
    this.optionWithBool.forEach(option => {
      if (option.val && !option.addition)
        selectedSku.push(option.product.sku);
      if (option.val && option.addition)
        selectedAdditions.push(option.product);
    })

    this.modalCtrl.dismiss({
      'status': status,
      'selected': selectedSku,
      'additions': selectedAdditions
    })
  }
}
