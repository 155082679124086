import {Component, isDevMode, ViewChild} from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Hjem',
      url: '/',
      icon: 'home'
    }
  ];

  @ViewChild("app") app

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService
  ) {
    this.initializeApp();
  }

  initializeApp() {

    // fallback language
    this.translate.setDefaultLang('de');
    this.translate.setDefaultLang('en');
    this.translate.setDefaultLang('da');

    // browser language
    this.translate.use(this.translate.getBrowserLang());


    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (!isDevMode()) {
        document.addEventListener('contextmenu', event => event.preventDefault());
        this.app.el.style.cursor = 'none';
      }
    });
  }
}
