import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {BackendService} from '../../../services/backend.service';
import {Config} from '../../../entities/config';
import {AuthService} from '../../../services/auth.service';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-menu',
    templateUrl: './club-menu.component.html',
    styleUrls: ['./club-menu.component.scss'],
})
export class ClubMenuComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    user: any;
    token: string;
    userInfo: any;
    balance: any;

    pointProducts = [];

    timer = 30;
    interval;
    userConfig: Config;

    constructor(
        private modalCtrl: ModalController,
        private backendService: BackendService,
        private auth: AuthService,
        public styleService: StyleService) { }

    ngOnInit() {
        this.auth.getUserId().then(userId => {
            this.backendService.getPosConfig(userId).subscribe(config => {
                this.userConfig = config;
                this.backendService.getClubProducts(this.token, this.userConfig.user_id).subscribe(resp => {
                    console.log(resp);
                    this.pointProducts = resp;
                })
            })
          });

        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    getPoints(product) {
        let int_info: string = product.integration_info;
        let split = int_info.indexOf(':') + 1;
        return int_info.substring(split);
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, product = undefined) {
        clearInterval(this.interval)
        let data = {
            'status': status
        };
        if (product) {
            data['product'] = product;
        }
        this.modalCtrl.dismiss(data)
    }

    clickBuy(product) {
        this.dismiss('purchase', product)
    }
}
