import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
    selector: 'app-nfc-error',
    templateUrl: './nfc-error.component.html',
    styleUrls: ['./nfc-error.component.scss'],
})
export class NfcErrorComponent implements OnInit {
    public readonly StyleType = StyleType;

    @Input()
    interval: any;
    state;
    message: any;
    baseTime = 20;
    timer = this.baseTime;

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService
    ) { }

    ngOnInit() {
        this.setTimer();
    }

    dismiss(status) {
        clearInterval(this.interval);
        this.modalCtrl.dismiss({
          'status': status
        })
      }
    setTimer() {
        clearInterval(this.interval)
        this.timer = this.baseTime;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                clearInterval(this.interval)
                this.dismiss("")
            }
        }, 1000)
    }
}
