import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-signup-fail',
    templateUrl: './club-signup-fail.component.html',
    styleUrls: ['./club-signup-fail.component.scss'],
})
export class ClubSignupFailComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    timer = 30;
    interval;

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer');
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
