// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: 'https://stage.api.switchpay.eu/mobilepayapi/api.php/',
  //api: 'https://prod01.api.switchpay.eu/mobilepayapi/api.php/',
  //api: 'http://localhost:8000/mobilepayapi/src/public/api.php/',
  firebase: {
    apiKey: "AIzaSyDO6PxAIw01xoShXCkeBhKXv98mGVBbXzo",
    authDomain: "switchpay-181807.firebaseapp.com",
    databaseURL: "https://switchpay-181807.firebaseio.com",
    projectId: "switchpay-181807",
    storageBucket: "switchpay-181807.appspot.com",
    messagingSenderId: "292548876849",
    appId: "1:292548876849:web:b399c3b730dfbac12c9310"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
