import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-login-keypad',
    templateUrl: './club-login-keypad.page.html',
    styleUrls: ['./club-login-keypad.page.scss'],
})
export class ClubLoginKeypadPage implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    timer = 30;
    interval;

    display: string[] = [];

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status, code: string = undefined) {
        clearInterval(this.interval);
        this.modalCtrl.dismiss({
            'status': status,
            'code': code
        })
    }

    keypadDelete() {
        this.display.pop();
    }

    keypadClear() {
        this.display = [];
    }

    async keypadClick(number) {
        if (this.display.length < 6) {
            this.display.push(number)
        }

        if (this.display.length === 6) {
            await this.keypadFilled()
        }
    }

    async keypadFilled() {
            let value = "";
            this.display.forEach(char => {
                value += char;
            })
        console.log(value);

            this.dismiss('next', value)
    }

    getDisplayItem(position) {
        let length = this.display.length;
        if (position === (length - 1)) {
            return this.display[position]
        }
        if (position >= length) {
            return "";
        }

        if (position < (length - 1)) {
            return "*";
        }
    }
}
