import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-generic-keypad',
  templateUrl: './generic-keypad.component.html',
  styleUrls: ['./generic-keypad.component.scss'],
})

export class GenericKeypadComponent implements OnInit {
    public readonly StyleType = StyleType;

    interval: any;

    code_entered = false;

    display: string[] = [];

    constructor(
        private modalCtrl: ModalController,
        public styleService: StyleService
    ){}

    ngOnInit() {}

    async dismiss(status, data = undefined) {
        clearInterval(this.interval);
        if (!data) {
            data = {'status': status}
        }
        await this.modalCtrl.dismiss(data)
    }

    keypadDelete() {
        this.display.pop();
    }

    async keypadClick(number) {
        if (this.display.length < 6) {
            this.display.push(number)

            if (this.display.length === 6) {
                await this.keypadFilled()
            }
        }
    }

    async keypadFilled() {
        let value = '';
        this.display.forEach(char => {
            value += char;
        });

        if (this.code_entered)
            return;
        this.code_entered = true;

        this.dismiss("code", {'code': value});
    }

    getDisplayItem(position) {
        let length = this.display.length;
        if (position === (length - 1)) {
            return this.display[position]
        }
        if (position >= length) {
            return "";
        }
        if (position < (length - 1)) {
            return "*";
        }
    }
}
