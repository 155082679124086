import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {IonicStorageModule} from '@ionic/storage';
import {QRCodeModule} from 'angularx-qrcode';
import {PaymentSelectorComponent} from './modals/payment-selector/payment-selector.component';
import {CountdownComponent} from './modals/countdown/countdown.component';
import {PaymentCompleteComponent} from './modals/payment-complete/payment-complete.component';
import {PaymentFailedComponent} from './modals/payment-failed/payment-failed.component';
import {ReceiptComponent} from './modals/receipt/receipt.component';
import {EmailReceiptComponent} from './modals/email-receipt/email-receipt.component';
import {SMSReceiptComponent} from './modals/sms-receipt/sms-receipt.component';
import {ReceiptResultComponent} from './modals/receipt-result/receipt-result.component';
import {ClubSignupNameComponent} from './modals/club/club-signup-name/club-signup-name.component';
import {ClubSignupPhoneComponent} from './modals/club/club-signup-phone/club-signup-phone.component';
import {ClubSignupComponent} from './modals/club/club-signup-success/club-signup.component';
import {ClubInfoComponent} from './modals/club/club-info/club-info.component';
import {ClubSignupFailComponent} from './modals/club/club-signup-fail/club-signup-fail.component';
import {ClubMenuComponent} from './modals/club/club-menu/club-menu.component';
import {ClubEditComponent} from './modals/club/club-edit/club-edit.component';
import {ClubLoginKeypadPage} from './modals/club/club-login-keypad/club-login-keypad.page';
import {ClubPaymentAcceptComponent} from './modals/club/club-payment-accept/club-payment-accept.component';
import {ClubPaymentCompleteComponent} from './modals/club/club-payment-complete/club-payment-complete.component';
import {ResumeProgramComponent} from './modals/resume-program/resume-program.component';
import {OptionSelectorComponent} from './modals/option-selector/option-selector.component';
import {FormsModule} from '@angular/forms';
import {ClubContactlessNoticeComponent} from './modals/club/club-contactless-notice/club-contactless-notice.component';
import {ContactlessPaymentComponent} from './modals/contactless-payment/contactless-payment.component';
import { VoucherKeypadPage } from './modals/voucher-keypad/voucher-keypad.page';
import { VoucherProductsComponent } from './modals/voucher-products/voucher-products.component';
import { ProductSelectorComponent } from './modals/products-selector/products-selector.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LoyaltyPaymentCompleteComponent } from './modals/loyalty-payment-complete/loyalty-payment-complete.component';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClubSelectComponent } from './modals/club/club-select/club-select.component';
import { NavigationComponent } from './navigation/navigation.component';
import { LoyaltyPaymentKeypadComponent } from './modals/loyalty-payment-keypad/loyalty-payment-keypad.component';
import { PaymentComponent } from './modals/payment/payment.component';
import { NfcAcceptedComponent } from './modals/nfc-accepted/nfc-accepted.component';
import { NfcErrorComponent } from './modals/nfc-error/nfc-error.component';
import { NfcStartComponent } from './modals/nfc-start/nfc-start.component';
import { Observable } from 'rxjs';
import {LoyaltyPaymentAcceptComponent} from './modals/loyalty-payment-accept/loyalty-payment-accept.component';
import {GenericKeypadComponent} from './modals/generic-keypad/generic-keypad.component';
import {LoyaltyProductsComponent} from './modals/loyalty-products/loyalty-products.component';

export class TranslationLoader implements TranslateLoader {
    constructor(private http: HttpClient) { }

    getTranslation(lang: string): Observable<any> {
        var userId = localStorage.getItem('user_id');
        if(userId) return this.http.get(environment.api + "translation/translation/1/"+ userId +"/" + lang);
        if(!userId) return this.http.get(environment.api + "translation/translation/1/0000/" + lang);
    }
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PaymentSelectorComponent,
        CountdownComponent,
        PaymentCompleteComponent,
        PaymentFailedComponent,
        ReceiptComponent,
        EmailReceiptComponent,
        SMSReceiptComponent,
        ReceiptResultComponent,
        ClubSelectComponent,
        ClubSignupNameComponent,
        ClubSignupPhoneComponent,
        ClubSignupComponent,
        ClubSignupFailComponent,
        ClubInfoComponent,
        ClubMenuComponent,
        ClubEditComponent,
        ClubLoginKeypadPage,
        ClubPaymentAcceptComponent,
        ClubPaymentCompleteComponent,
        ResumeProgramComponent,
        OptionSelectorComponent,
        ClubContactlessNoticeComponent,
        ContactlessPaymentComponent,
        VoucherKeypadPage,
        VoucherProductsComponent,
        ProductSelectorComponent,
        LoyaltyPaymentCompleteComponent,
        NavigationComponent,
        LoyaltyPaymentKeypadComponent,
        LoyaltyPaymentAcceptComponent,
        PaymentComponent,
        NfcAcceptedComponent,
        NfcErrorComponent,
        NfcStartComponent,
        GenericKeypadComponent,
        LoyaltyProductsComponent
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios'
        }),
        AppRoutingModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        QRCodeModule,
        FormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (HttpLoaderFactory),
            deps: [HttpClient]
        }
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  }
