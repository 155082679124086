import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {BackendService} from '../../services/backend.service';
import {Product} from '../../entities/product';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
    selector: 'app-loyalty-payment-accept',
    templateUrl: './loyalty-payment-accept.component.html',
    styleUrls: ['./loyalty-payment-accept.component.scss'],
})
export class LoyaltyPaymentAcceptComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;
    user: any;
    selectedAdditions: Product[] = [];

    customer: any;

    timer = 30;
    interval: any;

    orderId;
    orderText = "";

    clickedConfirm = false;

    constructor(private modalCtrl: ModalController,
                private backendService: BackendService,
                private loadingController: LoadingController,
                public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000);
        this.setup();
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    setup() {
        this.orderText = `${this.product.name || '-'} (${(Math.floor(this.product.price)) || '-'} kr.)`;
        if (this.selectedAdditions && this.selectedAdditions.length > 0) {
            this.selectedAdditions.forEach(add => {
                this.orderText += `, ${add.name || '-'} (${(Math.floor(add.price)) || '-'} kr.)`;
            })
        }
        this.backendService.createOrderForLoyalty(this.product.price, this.product.sku).subscribe(resp => {
            this.orderId = resp.id;
            this.backendService.getLoyaltyCustomer(this.user.loyalty_customer_id).subscribe(resp => {
                this.customer = resp;
            })
        })
    }

    dismiss(status, queueNumber = null) {
        clearInterval(this.interval);
        let paymentStatus = 2;
        if (status === 'captured')
            paymentStatus = 3;
        this.backendService.updateOrderForLoyalty(this.orderId, paymentStatus).subscribe(() => {
            this.modalCtrl.dismiss({
                'status': status,
                'queueNumber': queueNumber
            })
        })
    }

    async clickConfirm() {
        if (this.clickedConfirm)
            return;
        this.clickedConfirm = true;

        const loading = await this.loadingController.create({
            animated: false,
            duration: 30000
        });

        await loading.present().then(() => {
            this.backendService.updateOrderForLoyalty(this.orderId, 4).subscribe(() => {
                let additions: string[] = [];
                if (this.selectedAdditions && this.selectedAdditions.length > 0) {
                    additions = [];
                    this.selectedAdditions.forEach(add => {
                        additions.push(add.sku);
                    })
                }
                this.backendService.runLoyalty(this.product.sku, this.product.customer_id, this.user.loyalty_code, additions, this.orderId).subscribe(resp => {
                    loading.dismiss();

                    this.dismiss('captured', resp.queueNumber);
                }, error => {
                    console.log(error);
                    loading.dismiss();
                    this.dismiss('error', null);
                });
            });
        });

    }
}
