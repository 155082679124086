import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Storage} from "@ionic/storage";
import {environment} from '../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private storage: Storage) { }

  signIn(email: string, password: string): Observable<any> {
    return new Observable<any>(observer => {
      const url = environment.api + 'login/terminaltoken';
      const body = {
        'email': email,
        'password': password
      };
      this.http.post<any>(url, body).subscribe(
         res => this.storage.clear().then(
            () => this.storage.set('token', res.token).then(
               () => this.storage.set('products', res.products).then(
                  () => this.storage.set('pos_id', res.pos_unit_id).then(
                    () => this.storage.set('user_id', res.user.id).then(
                      () => {
                          localStorage.setItem('user_id',JSON.stringify(res.user.id));
                          observer.next(email);
                          observer.complete();
                      }))))),
         err => {
           observer.error(err);
           observer.complete();
         }
      )
    });
  }

  signOut(): Observable<any> {
    return new Observable<any>(observer => observer.next(this.storage.remove('token').then()));
  }

  isUserLoggedIn(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.storage.get('token').then(token => observer.next(!!token));
    });
  }
  
  getUserId(): Promise<number> {
    const jwt = new JwtHelperService();
    return this.storage.get('user_id');
  }

  signInClub(code: string, customerId: number) {
      return new Observable<any>(observer => {
          const url = environment.api + 'login/clubtoken';
          const body = {
              'code': code,
              'customer_id': customerId
          };
          this.http.post<any>(url, body).subscribe(
              res => observer.next(res),
              err => observer.error(err)
          );
      })
  }
}
