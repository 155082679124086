export class Product {
   sku: string;
   customer_id: number;
   price: number;
   pos_unit_id: string;
   variant_id: number;
   ordering: number;
   variant_name: string;
   free_vend: boolean;
   variant_image_url: string;
   enabled: boolean;
   name: string;
   group_id: number;
   group_name: string;
   group_description: string;
   disabled_state: string;
   disabled_colour: string;
   integration: string;
   integration_info: string;
   extra_text: number;
   resumable: boolean;

   // Local variables. These are not in the response from the API
   amount: number;
}
