import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  useChip = false;

  SetFirebaseMessage(message: String, type: String) {

    // use chip
    if(type == "use_chip") {
      if(message == "true") {
        this.useChip = true;
      } else {
        this.useChip = false;
      }
    }

    return true;
  }
}
