import { Component, Input, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-voucher-products',
  templateUrl: './voucher-products.component.html',
  styleUrls: ['./voucher-products.component.scss'],
})
export class VoucherProductsComponent implements OnInit {
  public readonly StyleType = StyleType;

  @Input()
  products: any;
  code: number;
  invalidProduct = false
  
  math = Math;
  interval;
  timer = 30;

  constructor(
      private modalCtrl: ModalController,
      public styleService: StyleService) {}

  ngOnInit() {
    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        this.dismiss('timer')
      }
    }, 1000)
  }
  ngOnDestroy() {
    clearInterval(this.interval)
  }

  dismiss(status) {
    this.modalCtrl.dismiss({
      'status': status,
    })
  }

  productSelected(productSku) {
    this.modalCtrl.dismiss({
      'status': 'next',
      'productSku': productSku
    });
  }

  dismissInvalidNotice() {
    this.timer = 30;
    this.invalidProduct = false;
  }

}
