import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Storage} from "@ionic/storage";
import {Order} from "../entities/order";
import {Product} from '../entities/product';
import {environment} from '../../environments/environment';
import {Config} from '../entities/config';
import { Language } from '../entities/language';
import { ControlPanelType } from '../entities/controlpaneltype';
import { DraftBeerItem } from '../entities/draft-beer-item';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(private http: HttpClient, private storage: Storage) { }

  getdraftpanelItems(serial_nr: string): Observable<DraftBeerItem[]> {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'posconfig/draftpanel/getdraftpanelitems/' + serial_nr;
        const options = { headers: new HttpHeaders({'Authorization': token})};
        this.http.get<DraftBeerItem[]>(url, options).subscribe(res => observer.next(res), err => observer.error(err));
      });
    });
  }

  setdraftState(id: number, state: number): Observable<any> {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'posconfig/draftpanel/setdraftstate/' + id + '/' + state;
        const options = { headers: new HttpHeaders({'Authorization': token})};
        this.http.get<any>(url, options).subscribe(res => observer.next(res), err => observer.error(err));
      });
    });
  }

  resetdraftcounter(id: number): Observable<any> {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'posconfig/draftpanel/resetdraftcounter/' + id;
        const options = { headers: new HttpHeaders({'Authorization': token})};
        this.http.get<any>(url, options).subscribe(res => observer.next(res), err => observer.error(err));
      });
    });
  }

  getLanguages(userId: number): Observable<Language[]> {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'translation/languageactivestate/' + userId;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.get<Language[]>(url, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }

  resetPayterCloudTerminal(productSku: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'payter/reset/' + productSku;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.get<boolean>(url, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }

  removeFirebeaseMessage(id: number): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/firebaseremoveitem/' + id;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.get<boolean>(url, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }

  createOrder(sku: string, payment_type?: number, club_user: number = undefined, additions = null) {
    return new Observable<Order>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'orders/terminal';

        const body = {
          'sku': sku,
          'payment_type': payment_type
        };

        if (additions) {
          body['additions'] = additions;
        }

        if (club_user) {
          body['club_user'] = club_user;
        }

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.post<Order>(url, body, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      })
    })
  }
  
  getPosConfig(userId: number): Observable<Config> {
    const url = environment.api + 'posconfig/' + userId;
    return this.http.get<Config>(url);
  }

  getControlpaneltypes(posconfigId) {
    return new Observable<ControlPanelType[]>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'posconfig/terminal/controlpaneltypes/' + posconfigId;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.get<ControlPanelType[]>(url, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }
  
  createOrderVariable(sku: string, price: number, count: number, payment_type?: number, discountCode = null) {
    return new Observable<Order>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'orders/terminal/variable';

        let body = {
          'sku': sku,
          'payment_type': payment_type,
          'price': price,
          'count': count,
          'discount_code_id': discountCode
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.post<Order>(url, body, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      })
    })
  }

  createFreeOrder(sku: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/activatefree';

        const body = {
          'sku': sku
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.post<any>(url, body, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      })
    })
  }

  cancelOrder(id: number): Observable<Order> {
    return new Observable<Order>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'orders/cancel/' + id;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.put<Order>(url, null, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }

  getProducts(): Observable<Product[]> {
    return new Observable<Product[]>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/getproducts';

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };

        this.http.get<Product[]>(url, options).subscribe(
           res => observer.next(res),
           err => observer.error(err)
        );
      });
    });
  }

  runLoyalty(sku: string, customerId: number, loyalty_code: string, additions: string[] = null, order_id = null) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/activateloyalty';
        const body = {
          'sku': sku,
          'customer_id': customerId,
          'loyalty_code': loyalty_code,
          'additions': additions,
          'order_id': order_id
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  registerClubUser(name: string, phone: string, customerId: number) {
    return new Observable<any>(observer => {
        const url = environment.api + 'login/registerClubUser';
        const body = {
          'name': name,
          'phone': phone,
          'customer_id': customerId
        };
        this.http.post<any>(url, body).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
    })
  }

  createOrderForLoyalty(price: number, sku) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/loyalty/order/create';
        const body = {
          'sku': sku,
          'price': price
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  updateOrderForLoyalty(order_id: number, status: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/loyalty/order/update';
        const body = {
          'order_id': order_id,
          'status': status
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.put<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  GetLoyaltyUserByLoyaltyCode(customer_id, loyalty_code: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/getloyaltyuserbyloyaltycode';
        const body = {
          'customer_id': customer_id,
          'loyalty_code': loyalty_code
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  getLoyaltyCustomer(loyalty_customer_id) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/getloyaltycustomer';
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          }),
          params: {
            'loyalty_customer_id': loyalty_customer_id
          }
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  editClubUserPhone(phone, newPhone, token) {
    return new Observable<any>(observer => {
        const url = environment.api + 'club/user';
        const body = {
          'phone': phone,
          'new_phone': newPhone
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.put<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
    })
  }

  clubUserRequestNewCode(phone, customer_id) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'club/code/new';
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          }),
          params: {
            'phone': phone,
            'customer_id': customer_id
          }
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  getClubProducts(token, pos_user = null) {
    return new Observable<any>(observer => {
      let url = environment.api + 'club/products';
      if(pos_user) url = environment.api + 'club/products?posuser=' + pos_user;
      const options = {
        headers: new HttpHeaders({
          'Authorization': token
        })
      };
      this.http.get<any>(url, options).subscribe(
          res => observer.next(res),
          err => observer.error(err)
      );
    });
  }

  createOrderForClub(sku: string, token) {
    return new Observable<any>(observer => {
        const url = environment.api + 'club/order';
        const body = {
          'sku': sku
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
    })
  }

  runClub(sku: string, customerId: number, code: string, orderId, token) {
    return new Observable<any>(observer => {
        const url = environment.api + 'club/activate';
        const body = {
          'sku': sku,
          'customer_id': customerId,
          'code': code,
          'order_id': orderId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
    })
  }

  sendEmailReceipt(recipient: string, point_of_sale: string, sale_method: string, date: string, time: string, product_name: string,
                   product_price: string, vendor: string, vendor_address: string, vendor_cvr: string, receiptId: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/receipt/mail';
        const body = {
          'recipient': recipient,
          'point_of_sale': point_of_sale,
          'sale_method': sale_method,
          'date': date,
          'time': time,
          'product_name': product_name,
          'product_price': product_price,
          'vendor': vendor,
          'vendor_address': vendor_address,
          'vendor_cvr': vendor_cvr,
          'receipt_nr': receiptId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  sendEmailReceiptAddition(recipient: string, point_of_sale: string, sale_method: string, date: string, time: string, product_name: string,
                   product_price: string, product2_name: string, product2_price: string, vendor: string, vendor_address: string, vendor_cvr: string, receiptId: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/receipt/mail/addition';
        const body = {
          'recipient': recipient,
          'point_of_sale': point_of_sale,
          'sale_method': sale_method,
          'date': date,
          'time': time,
          'product_name': product_name,
          'product_price': product_price,
          'product2_name': product2_name,
          'product2_price': product2_price,
          'vendor': vendor,
          'vendor_address': vendor_address,
          'vendor_cvr': vendor_cvr,
          'receipt_nr': receiptId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  sendSmsReceipt(recipient: string, point_of_sale: string, sale_method: string, date: string, time: string, product_name: string,
                 product_price: string, vendor: string, vendor_address: string, vendor_cvr: string, receiptId: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/receipt/sms';
        const body = {
          'recipient': recipient,
          'point_of_sale': point_of_sale,
          'sale_method': sale_method,
          'date': date,
          'time': time,
          'product_name': product_name,
          'product_price': product_price,
          'vendor': vendor,
          'vendor_address': vendor_address,
          'vendor_cvr': vendor_cvr,
          'receipt_nr': receiptId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  sendSmsReceiptAddition(recipient: string, point_of_sale: string, sale_method: string, date: string, time: string, product_name: string,
                 product_price: string, product2_name: string, product2_price: string, vendor: string, vendor_address: string, vendor_cvr: string, receiptId: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/receipt/sms/addition';
        const body = {
          'recipient': recipient,
          'point_of_sale': point_of_sale,
          'sale_method': sale_method,
          'date': date,
          'time': time,
          'product_name': product_name,
          'product_price': product_price,
          'product2_name': product2_name,
          'product2_price': product2_price,
          'vendor': vendor,
          'vendor_address': vendor_address,
          'vendor_cvr': vendor_cvr,
          'receipt_nr': receiptId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  createReceipt(customerId: number, sku: string, created, price: number, orderId: number) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/receipt/create';
        const body = {
          'customerId': customerId,
          'sku': sku,
          'created': created,
          'price': price,
          'orderId': orderId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  runResume(sku: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/resume';
        const body = {
          'sku': sku
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  addOptionTrigger(sku: string, host_sku: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/addoptiontrigger';
        const body = {
          'sku': sku,
          'host_sku': host_sku
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  addOrderAddition(sku: string, orderId: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/addorderaddition';
        const body = {
          'sku': sku,
          'order_id': orderId
        };
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  getProductOptions(sku) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
      const url = environment.api + 'terminals/getproductoptions';
      const options = {
        headers: new HttpHeaders({
          'Authorization': token
        }),
        params: {
          'sku': sku
        }
      };
      this.http.get<any>(url, options).subscribe(
          res => observer.next(res),
          err => observer.error(err)
      );
      })
    })
  }

  getProductAdditions(sku) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/getproductadditions';
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          }),
          params: {
            'sku': sku
          }
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  getContactlessExtraText(id) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/getcontactlessextratext';
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          }),
          params: {
            'id': id
          }
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  voucherProducts(code) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'vouchers/selectvoucher/' + code;
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  voucherRedeem(code, productSku) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'vouchers/redeem/'+ code +'/' + productSku;
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  addTerminalLog(message: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/logs';
        const body = {
          'message': message
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  sendMifareScan(orderId) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/sendmifarescan';
        const body = {
          'order_id': orderId
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
    })
  }

  checkDiscountCode(code) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/discountcode';
        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          }),
          params: {
            'code': code
          }
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      })
      })
  }

  sendMeasurementResume(sku) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/measuring/sendresume';
        const body = {
          'sku': sku
        };

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.post<any>(url, body, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      });
    });
  }

  checkHiddenLoyaltyProducts(code: string) {
    return new Observable<any>(observer => {
      this.storage.get('token').then(token => {
        const url = environment.api + 'terminals/loyalty/hidden/' + code;

        const options = {
          headers: new HttpHeaders({
            'Authorization': token
          })
        };
        this.http.get<any>(url, options).subscribe(
            res => observer.next(res),
            err => observer.error(err)
        );
      });
    });
  }
}


