import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Product} from '../../../entities/product';
import {BackendService} from '../../../services/backend.service';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-contactless-notice',
    templateUrl: './club-contactless-notice.component.html',
    styleUrls: ['./club-contactless-notice.component.scss'],
})
export class ClubContactlessNoticeComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;

    math = Math

    extraText = undefined;

    timer = 30;
    interval;

    constructor(
        private modalCtrl: ModalController,
        private backendService: BackendService,
        public styleService: StyleService) { }

    ngOnInit() {
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('timer')
            }
        }, 1000)

        this.backendService.getContactlessExtraText(this.product.extra_text).subscribe(resp => {
            if (resp) {
                this.extraText = resp.text;
            }
        })
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    dismiss(status) {
        clearInterval(this.interval)
        this.modalCtrl.dismiss({
            'status': status
        })
    }
}
