import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {BackendService} from "../../services/backend.service";
import {Product} from '../../entities/product';
import {LoyaltyPaymentAcceptComponent} from '../loyalty-payment-accept/loyalty-payment-accept.component';
import {AuthService} from '../../services/auth.service';
import {ClubPaymentAcceptComponent} from '../club/club-payment-accept/club-payment-accept.component';
import {StyleService, StyleType} from '../../services/style.service';

@Component({
  selector: 'app-loyalty-payment-keypad',
  templateUrl: './loyalty-payment-keypad.component.html',
  styleUrls: ['./loyalty-payment-keypad.component.scss'],
})

export class LoyaltyPaymentKeypadComponent implements OnInit {
    public readonly StyleType = StyleType;

    @Input()
    product: Product;
    clubAllowed: boolean;
    loyaltyAllowed: boolean;
    voucherAllowed: boolean;
    selectedAdditions: string[] = [];
    interval: any;

    code_wrong = false;
    code_entered = false;

    display: string[] = [];

    constructor(
        private modalCtrl: ModalController, 
        private backendService: BackendService, 
        private alertController: AlertController, 
        private auth: AuthService,
        public styleService: StyleService
    ){}

    ngOnInit() {}

    async dismiss(status, data = undefined, queueNumber = null) {
        clearInterval(this.interval);
        if (!data) {
            data = {'status': status, 'queueNumber': queueNumber}
        }
        await this.modalCtrl.dismiss(data)
    }

    keypadDelete() {
        this.code_wrong = false;
        this.display.pop();
    }

    async keypadClick(number) {
        if (this.display.length < 6) {
            this.display.push(number)

            if (this.display.length === 6) {
                await this.keypadFilled()
            }
        }
    }

    async keypadFilled() {
        let value = '';
        this.display.forEach(char => {
            value += char;
        });

        if (this.code_entered)
            return;
        this.code_entered = true;


        if (this.clubAllowed) {
            this.auth.signInClub(value, this.product.customer_id).subscribe(async resp => {
                await this.launchAcceptClubModal(this.product, resp.user, resp.userInfo, resp.balance, resp.token, this.selectedAdditions);
                this.code_entered = false;
            }, async () => {
                if (this.loyaltyAllowed) {
                    this.codeAttemptLoyalty(value);
                } else if (this.voucherAllowed) {
                    this.codeAttemptvoucher(value);
                } else {
                    this.code_wrong = true;
                    this.code_entered = false;
                }
            });
        } else if (this.loyaltyAllowed) {
            this.codeAttemptLoyalty(value);
        } else {
            this.code_entered = false;
        }
    }

    codeAttemptLoyalty(value) {
        this.backendService.GetLoyaltyUserByLoyaltyCode(this.product.customer_id, value).subscribe(async resp => {
            if (resp && resp.id) {
                await this.launchAcceptModal(this.product, resp, this.selectedAdditions);
            } else if (this.voucherAllowed) {
                this.codeAttemptvoucher(value);
            } else {
                this.code_wrong = true;
                this.code_entered = false;
            }
        }, async error => {
            console.log(error);
            await this.presentAlert('Kunne ikke kontrollere kode');
            this.code_entered = false;
        });
    }

    codeAttemptvoucher(code) {
        this.backendService.voucherProducts(code).subscribe(voucher => {
            let data = {'status': 'voucher', 'voucher': voucher}
            this.dismiss('voucher', data);
            this.code_entered = false;
        }, err => {
            console.log(err);
            this.code_entered = false;
            this.code_wrong = true;
        });
    }

    getDisplayItem(position) {
        let length = this.display.length;
        if (position === (length - 1)) {
            return this.display[position]
        }
        if (position >= length) {
            return "";
        }
        if (position < (length - 1)) {
            return "*";
        }
    }

    async presentAlert(message: string, auto_dismiss: boolean = false) {
        const alert = await this.alertController.create({
            animated: false,
            header: message,
            buttons: [{
                text: 'OK'
            }]
        });

        await alert.present();
        if (auto_dismiss) {
            setTimeout(() => {
                alert.dismiss();
            },3000);
        }
    }

    async launchAcceptModal(product: Product, user, additions) {
        let component: any = LoyaltyPaymentAcceptComponent;
        let cssClass: string = 'loyalty-payment-accept-modal';

        let modal = await this.modalCtrl.create({
            animated: false,
            component: component,
            cssClass: cssClass,
            componentProps: {
                'product': product,
                'user': user,
                'selectedAdditions': additions
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(modal => {
            if (modal.data != null && modal.data.status != 'dismiss') {
                this.dismiss(modal.data.status, undefined, modal.data.queueNumber)
            } else {
                this.dismiss('dismiss')
            }
        })
    }

    async launchAcceptClubModal(product: Product, user, userInfo, balance, token, additions) {
        let component: any = ClubPaymentAcceptComponent;
        let cssClass: string = 'payment-result-modal';

        let modal = await this.modalCtrl.create({
            animated: false,
            component: component,
            cssClass: cssClass,
            componentProps: {
                'product': product,
                'user': user,
                'userInfo': userInfo,
                'balance': balance,
                'token': token,
                'selectedAdditions': additions
            }
        });

        await modal.present();
        await modal.onDidDismiss().then(modal => {
            if (modal.data == undefined || modal.data.status == undefined) {
                this.dismiss('dismiss')
            } else if (modal.data.status == 'purchase') {
                this.dismiss(modal.data.status, modal.data);
            } else {
                this.dismiss(modal.data.status, modal.data)
            }
        })
    }
}
