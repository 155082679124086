import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AlertController, ModalController} from '@ionic/angular';
import {BackendService} from '../../../services/backend.service';
import {ClubSignupPhoneComponent} from '../club-signup-phone/club-signup-phone.component';
import {AuthService} from '../../../services/auth.service';
import {StyleService, StyleType} from '../../../services/style.service';

@Component({
    selector: 'app-club-edit',
    templateUrl: './club-edit.component.html',
    styleUrls: ['./club-edit.component.scss']
})
export class ClubEditComponent implements OnInit, OnDestroy {
    public readonly StyleType = StyleType;

    @Input()
    user: any;
    token: string;
    userInfo: any;
    balance: any;
    customerId: any;

    timer = 30;
    interval;

    alert;

    constructor(
        private modalCtrl: ModalController,
        private backendService: BackendService,
        private alertController: AlertController,
        private authService: AuthService,
        public styleService: StyleService) { }

    ngOnInit() {
        this.setInterval();
    }

    setInterval() {
        this.timer = 30;
        this.interval = setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.dismiss('back')
            }
        }, 1000)
    }

    ngOnDestroy() {
        clearInterval(this.interval)
    }

    async dismiss(status) {
        clearInterval(this.interval)
        if (this.alert) {
            try {
                await this.alertController.dismiss();
            } catch (e){}
        }

        let data = {
            'status': status
        };
        data['user'] = this.user;
        data['userInfo'] = this.userInfo;
        data['token'] = this.token;
        data['balance'] = this.balance;

        await this.modalCtrl.dismiss(data)
    }

    async clickEditPhone() {
        const modal = await this.modalCtrl.create({
            animated: false,
            component: ClubSignupPhoneComponent,
            cssClass: 'payment-result-modal',
            componentProps: {},
            backdropDismiss: false
        })
        await modal.present();
        clearInterval(this.interval)
        await modal.onDidDismiss().then(modal => {
            this.setInterval();
            if (!modal.data || !modal.data.status)
                return;
            else if (modal.data.status === 'next') {
                let number = modal.data.number;
                this.editNumber(number)
            }
        });

    }

    editNumber(newNumber) {
        this.backendService.editClubUserPhone(this.user.phone, newNumber, this.token).subscribe(() => {
            this.authService.signInClub(this.userInfo.loyalty_code, this.user.customer_id).subscribe(resp => {
                this.user = resp.user;
                this.token = resp.token;
                this.userInfo = resp.userInfo;
                this.balance = resp.balance;
            })
        }, async error => {
            console.log(error);
            this.alert = await this.alertController.create({
                animated: false,
                header: 'Kunne ikke ændre nummeret til det ønskede.',
                buttons: [{
                    text: 'OK'
                }]
            });
            this.alert.onDidDismiss(() => {
                this.alert = undefined;
            })
            await this.alert.present();
        })
    }

    async clickNewCode(phone) {
        this.backendService.clubUserRequestNewCode(phone, this.customerId).subscribe(async () => {
            this.alert = await this.alertController.create({
                animated: false,
                header: 'Du modtager en SMS med den nye kode.',
                buttons: [{
                    text: 'OK'
                }]
            });
            this.alert.onDidDismiss(() => {
                this.alert = undefined;
            })
            await this.alert.present();
        }, async error => {
            console.log(error);
            this.alert = await this.alertController.create({
                animated: false,
                header: 'Der skete en fejl, og systemet kunne ikke generere en ny kode.',
                buttons: [{
                    text: 'OK'
                }]
            });
            this.alert.onDidDismiss(() => {
                this.alert = undefined;
            })
        })
    }
}
